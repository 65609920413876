import React from 'react';
import img from '../images/1673032996phpxxRT8L.jpg';
import img2 from '../images/clients/Happy-Toys.jpg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';
import img5 from '../images/clients/burgerH.png'
import img6 from '../images/clients/ToyB.png'

const ComingSoon = () => {

    return (
        <div id="coming-soon" className="bg-gray-100 py-12 mt-12" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Coming Soon</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    {/* <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Coming Soon.</h2> */}
                </div>

                <div className="px-12" >
                    <div className="grid sm:grid-cols-2 lg:grid-cols-2 place-items-center gap-5">


                        <div className="bg-white overflow-hidden rounded-lg shadow-2xl p-9 group flex-row justify-center items-center w-fit justify-center">
                            <div style={{ "height": "250px", "width": "250px", display: 'flex', justifyContent: 'center' }}>
                                <img alt="card img" src={img2} />
                            </div>
                            <h2 className="font-semibold my-4 text-2xl text-center">Happy Toys</h2>
                        </div>
                        <div className="bg-white overflow-hidden rounded-lg shadow-2xl p-9 group flex-row justify-center items-center w-fit justify-center">
                            <div style={{ "height": "250px", "width": "250px", display: 'flex', justifyContent: 'center' }}>
                                <img alt="card img" src={img6} />
                            </div>
                            <h2 className="font-semibold my-4 text-2xl text-center">Toy Box</h2>
                            {/* <p className="text-md font-medium">
                                    We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                                </p> */}
                        </div>

                        {/* <div className="bg-white   overflow-hidden rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" src={img6} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Toy Box</h2>
                                {/* <p className="text-md font-medium">
                                    We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                                </p>
                            </div>
                        </div> */}
                        {/* <div className="bg-white  overflow-hidden  rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" src={img2} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Happy toys</h2>
                                {/* <p className="text-md font-medium">
                                    We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.
                                </p> 
                            </div>
                        </div> */}



                        <div >
                            <div className="m-2 text-justify text-sm">
                                {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} /> */}
                                <h2 className="font-semibold my-4 text-2xl text-center"></h2>
                                {/* <p className="text-md font-medium">
                                    We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                                </p> */}
                            </div>
                        </div>

                        {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">Domain and Hosting Services</h2>
                                <p className="text-md font-medium">
                                    We provide domain registration and web hosting services to individuals and organizations to enable them gain visibility in the digital space.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">General IT Consultations</h2>
                                <p className="text-md font-medium">
                                    Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.
                                </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

        </div>
    )
}

export default ComingSoon;