import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import WatermarkImage from '../images/clients/GVR(5).png';

const Careers = () => {
    useDocTitle('GVR LIMITED - Careers');

    const jobPosts = [
        {
            title: 'Sales Administrator',
            location: '77 New Road, Neath SA10 6HE',
            description: 'We are seeking a Sales Administrator to join our team. The ideal candidate will be organised, possess excellent communication skills, and have a strong background in sales administration and customer service. *Duties* - Provide administrative support to the sales team - Communicate with customers via phone and email - Utilise IT systems for sales administration tasks - Maintain accurate records of sales data - Assist in preparing sales reports using Word and Excel - Computerise sales orders and process invoices - Handle general administrative tasks to support the sales department.',
            responsibilities: [
                'Develop and maintain web applications.',
                'Collaborate with the design and product teams.',
                'Write clean and maintainable code.',
            ],
            qualifications: [
                'Strong organisational skills - Excellent communication abilities - Proficiency in Microsoft Outlook, Word, and Excel - Prior experience in sales administration is desirable - Competency in IT systems for data entry and record keeping'
            ],
            applyLink: 'apply' // Example email application link
        },
        {
            title: 'Shop Manager',
            location: '77 New rd, Skewen, Neath SA10 6HE',
            description: 'We are seeking a skilled Shop Manager to oversee our retail operations and ensure the smooth running of our shop. The ideal candidate will have excellent organisational skills and a flair for leadership.',
            responsibilities: [
                '- Manage day-to-day shop operations, including sales, inventory, and customer service',
                '- Supervise and train shop staff to deliver exceptional service',
                '- Communicate effectively with customers to understand their needs and provide assistance',
                '- Implement sales strategies to drive revenue and meet targets',
                '-Handle inventory management, including ordering supplies, managing stock levels and minimising waste.',
                '-Ensure compliance with health and safety regulations, address customer queries and complaints, manage cash drawers, record income and expenditures, and oversee inventory management.',
                '- Maintain a clean and organised shop environment',
                '- Handle customer queries and complaints in a professional manner'
            ],
            qualifications: [
                'Retail sales: 1 year (preferred)',
                'Supervising experience: 1 year (preferred)',
                'Customer service: 1 year (preferred)',
                'Retail management: 1 year (preferred)',
                'Management: 1 year (preferred)',
                'Driving Licence (preferred)'
            ],
            applyLink: 'mailto:gvrpvtlimiteduk@gmail.com?subject=Application for Shop Manager Position' // Example email application link
        },
        {
            title: 'Marketing Executive - job post',
            location: '77 New Road, Neath SA10 6HE',
            description: 'We are seeking a dynamic Marketing Executive to join our team. The ideal candidate will be responsible for developing and implementing marketing strategies to promote our products and services effectively.',
            responsibilities: [
                '- Oversee and execute marketing campaigns, both online and offline',
                '- Manage email marketing campaigns to engage with customers',
                '- Coordinate marketing projects and activities',
                '- Utilise IT tools for data analysis and reporting',
                '- Communicate with various stakeholders to ensure marketing objectives are met',
                '- Demonstrate leadership in driving marketing initiatives forward',
            ],
            qualifications: [
                'Proficiency in English, both written and verbal',
                '- Strong project management and time management skills',
                '- Knowledge of office applications and Google tools',
                '- Previous experience in a marketing role is desirable',
                '- Ability to work collaboratively in a team environment',
                '- Excellent communication skills to interact with internal and external partners',
                'Bachelors (preferred)',
                'Digital marketing: 1 year (preferred)',
                'Marketing: 1 year (preferred)'
            ],
            applyLink: 'mailto:gvrpvtlimiteduk@gmail.com?subject=Application for Marketing Executive Position' // Example email application link
        },
        {
            title: 'Sales Administrator',
            location: 'Precinct, 2 Cowell St, Llanelli SA15 1YL',
            description: 'We are seeking a skilled Sales Administrator to oversee our retail operations and ensure the smooth running of our shop. The ideal candidate will have excellent organisational skills and a flair for leadership.',
            responsibilities: [
                '- Assist the sales team with administrative tasks, including preparing sales reports, quotations, and contracts',
                '- Manage and update customer records in CRM systems',
                '- Handle customer inquiries and provide support to the sales team',
                '- Process orders, track inventory, and coordinate deliveries',
                '- Assist in scheduling meetings and coordinating communication between sales and other departments',
                '- Monitor and track sales performance against targets, providing support in the achievement of sales goals',
                '- Organize and maintain sales-related files and data for easy access',
                '- Handle customer complaints or issues related to sales orders and liaise with relevant teams for resolutions'
            ],
            qualifications: [
                'Bachelor’s degree in Business Administration, Marketing, or related field (preferred)',
                'Minimum of 1+ years of experience in a sales or administrative role',
                'Familiarity with sales processes and customer relationship management',
                'Experience in managing sales documentation and reporting',
                'Previous experience with order processing or sales support is a plus',
                'Driving Licence (preferred)'
            ],
            applyLink: 'mailto:gvrpvtlimiteduk@gmail.com?subject=Application for Sales Administrator Position' // Example email application link
        },
        {
            title: 'Marketing Executive',
            location: 'Precinct, 2 Cowell St, Llanelli SA15 1YL',
            description: 'We are seeking a dynamic Marketing Executive to join our team. The ideal candidate will be responsible for developing and implementing marketing strategies to promote our products and services effectively.',
            responsibilities: [
                '- Oversee and execute marketing campaigns, both online and offline',
                '- Manage email marketing campaigns to engage with customers',
                '- Coordinate marketing projects and activities',
                '- Utilise IT tools for data analysis and reporting',
                '- Communicate with various stakeholders to ensure marketing objectives are met',
                '- Demonstrate leadership in driving marketing initiatives forward',
            ],
            qualifications: [
                'Proficiency in English, both written and verbal',
                '- Strong project management and time management skills',
                '- Knowledge of office applications and Google tools',
                '- Previous experience in a marketing role is desirable',
                '- Ability to work collaboratively in a team environment',
                '- Excellent communication skills to interact with internal and external partners',
                'Bachelors (preferred)',
                'Digital marketing: 1 year (preferred)',
                'Marketing: 1 year (preferred)'
            ],
            applyLink: 'mailto:gvrpvtlimiteduk@gmail.com?subject=Application for Marketing Executive Position' // Example email application link
        },
        {
            title: 'Shop Manager',
            location: 'Precinct, 2 Cowell St, Llanelli SA15 1YL',
            description: 'We are seeking a skilled Shop Manager to oversee our retail operations and ensure the smooth running of our shop. The ideal candidate will have excellent organisational skills and a flair for leadership.',
            responsibilities: [
                '- Manage day-to-day shop operations, including sales, inventory, and customer service',
                '- Supervise and train shop staff to deliver exceptional service',
                '- Communicate effectively with customers to understand their needs and provide assistance',
                '- Implement sales strategies to drive revenue and meet targets',
                '-Handle inventory management, including ordering supplies, managing stock levels and minimising waste.',
                '-Ensure compliance with health and safety regulations, address customer queries and complaints, manage cash drawers, record income and expenditures, and oversee inventory management.',
                '- Maintain a clean and organised shop environment',
                '- Handle customer queries and complaints in a professional manner'
            ],
            qualifications: [
                'Retail sales: 1 year (preferred)',
                'Supervising experience: 1 year (preferred)',
                'Customer service: 1 year (preferred)',
                'Retail management: 1 year (preferred)',
                'Management: 1 year (preferred)',
                'Driving Licence (preferred)'
            ],
            applyLink: 'mailto:gvrpvtlimiteduk@gmail.com?subject=Application for Shop Manager Position' // Example email application link
        },
        {
            title: 'Business Analyst',
            location: 'Precinct, 2 Cowell St, Llanelli SA15 1YL',
            description: 'We are seeking a skilled Business Analyst to analyze, improve, and enhance our business operations and systems. The ideal candidate will possess a strong analytical mindset, exceptional communication skills, and experience in business process improvement.',
            qualifications: [
                '- Bachelor’s degree in Business Administration, Finance, Economics, or related field',
                '- Strong analytical and problem-solving skills',
                '- Excellent communication and presentation skills',
                '- Proficiency in data analysis tools and software (Excel, SQL, Tableau, etc.)',
                '- Familiarity with business process modeling and requirement gathering techniques',
                '- Ability to work with cross-functional teams and stakeholders',
                '- Strong attention to detail and organizational skills'
            ],
            experience: [
                '- Minimum of 2+ years of experience as a Business Analyst or in a related role',
                '- Experience in analyzing business processes, systems, and operations to identify areas of improvement',
                '- Proficient in gathering and documenting business requirements, as well as translating them into functional specifications',
                '- Experience in data analysis, report generation, and performance monitoring',
                '- Experience working in agile environments and with project management methodologies (e.g., Agile, Scrum) is a plus'
            ],
            responsibilities: [
                '- Analyze business operations, processes, and systems to identify inefficiencies and propose improvements',
                '- Gather and document business requirements from stakeholders and ensure they are clearly defined for development teams',
                '- Create detailed reports and presentations to communicate findings, recommendations, and project progress to stakeholders',
                '- Collaborate with business units to understand their needs and challenges, offering data-driven solutions',
                '- Support the design and implementation of business solutions, including process improvements, new systems, or software integrations',
                '- Assist with testing and validation of new systems or processes, ensuring they meet business requirements and user needs',
                '- Track key performance indicators (KPIs) to monitor the success of implemented solutions and identify opportunities for further improvements',
                '- Stay up-to-date with industry trends and best practices to provide valuable insights and innovative solutions'
            ],
            applyLink: 'mailto:gvrpvtlimiteduk@gmail.com?subject=Application for Business Analyst Position' // Example email application link
        }

    ];

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container my-8 px-4 lg:px-20 relative">
                    {/* Card container */}
                    <div className="relative w-full bg-white p-8 my-4">
                        {/* Watermark image */}
                        <img
                            src={WatermarkImage}
                            alt="Watermark"
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%', // or adjust size as needed
                                height: '100%', // or adjust size as needed
                                objectFit: 'contain',
                                opacity: 0.2, // Adjust opacity for watermark effect
                                zIndex: 10,  // Layer the watermark below the content
                                pointerEvents: 'none', // Ensures watermark doesn't block interactions
                            }}
                        />
                        <div className="relative z-20"> {/* Keep content above the watermark */}
                            <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">Careers</h1>
                            <p className="mt-4 text-gray-700">
                                We are always looking for talented individuals to join our team. Below are the jobs currently available:
                            </p>
                            <div className="mt-8">
                                {jobPosts.map((job, index) => (
                                    <div key={index} className="mb-8 rounded-2xl shadow-2xl p-5">
                                        <h2 className="text-xl font-semibold text-blue-900">
                                            <Link to={`/careers/${index}`}>{job.title}</Link>
                                        </h2>
                                        <p className="text-gray-600">{job.location}</p>
                                        <p className="mt-2 text-gray-700">{job.description}</p>

                                        {/* Flex container for Read More and Apply Now buttons */}
                                        <div className="flex items-center justify-between mt-4"> {/* Align items center */}
                                            {/* Read More link with underline */}
                                            <div className="mr-8"> {/* Add right margin for spacing */}
                                                <Link
                                                    to={`/careers/${index}`}
                                                    className="inline-block text-blue-900 underline hover:text-blue-800 transition duration-200"
                                                >
                                                    Read More..
                                                </Link>
                                            </div>

                                            {/* Apply Now button in a separate div */}
                                            <div>
                                                <a
                                                    href={`/apply?jobTitle=${encodeURIComponent(job.title)}`}
                                                    className="bg-blue-900 text-white px-6 py-2 rounded-md hover:bg-blue-800 transition duration-200"
                                                >
                                                    Apply Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Careers;
