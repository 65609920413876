import React from 'react';
import Simply from '../images/clients/simply.jpg';
import geps from '../images/clients/geps.png';
import protergia from '../images/clients/protergia.png';
import bookerWholesale from '../images/clients/booker_york_2_2133987965.png'
import tQuality from '../images/clients/645d7ce98db87a42878c3484_tquality.jpg'
import foodHUB from '../images/clients/foodhub.png'
import deloveroo from '../images/clients/deloveroo.png'
import justeat from '../images/clients/justeat.png'
import ubereats from '../images/clients/ubereats.png'
import joes from '../images/clients/joes.png'
import anatolian from '../images/clients/anatolian.jpeg'


const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <section data-aos="zoom-in-down">
            <div className="my-4 py-4">
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Patners</h2>

                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900'></div>
                </div>
            </div>

            <div className="px-12" >
                <div className="grid sm:grid-cols-3 lg:grid-cols-3 place-items-center gap-5">

                    <div className="bg-white overflow-hidden rounded-lg shadow-2xl p-9 group flex-row justify-center items-center w-fit justify-center">

                        <div style={{ "height": "250px", "width": "250px", display: 'flex', justifyContent: 'center' }}>
                            <img alt="card img" src={bookerWholesale} />
                        </div>
                        <h2 className="font-semibold my-4 text-2xl text-center">Booker Wholesale</h2>
                    </div>

                    <div className="bg-white overflow-hidden rounded-lg shadow-2xl p-9 group flex-row justify-center items-center w-fit justify-center">

                        <div style={{ "height": "250px", "width": "250px", display: 'flex', justifyContent: 'center' }}>
                            <img alt="card img" src={tQuality} />
                        </div>
                        <h2 className="font-semibold my-4 text-2xl text-center">T.Quality</h2>
                    </div>


                    <div className="bg-white overflow-hidden rounded-lg shadow-2xl p-9 group flex-row justify-center items-center w-fit">

                        <div style={{ "height": "250px", "width": "250px", display: 'flex', justifyContent: 'center' }}>
                            <img alt="card img" src={foodHUB} />
                        </div>
                        <h2 className="font-semibold my-4 text-2xl text-center">Food Hub</h2>
                    </div>

                    <div className="bg-white overflow-hidden rounded-lg shadow-2xl p-9 group flex-row justify-center items-center w-fit justify-center">

                        <div style={{ "height": "250px", "width": "250px", display: 'flex', justifyContent: 'center' }}>
                            <img alt="card img" src={deloveroo} />
                        </div>
                        <h2 className="font-semibold my-4 text-2xl text-center">Deloveroo</h2>
                    </div>

                    <div className="bg-white overflow-hidden rounded-lg shadow-2xl p-9 group flex-row justify-center items-center w-fit justify-center">

                        <div style={{ "height": "250px", "width": "250px", display: 'flex', justifyContent: 'center' }}>
                            <img alt="card img" src={ubereats} />
                        </div>
                        <h2 className="font-semibold my-4 text-2xl text-center">Uber Eats</h2>
                    </div>


                    <div className="bg-white overflow-hidden rounded-lg shadow-2xl p-9 group flex-row justify-center items-center w-fit">

                        <div style={{ "height": "250px", "width": "250px", display: 'flex', justifyContent: 'center' }}>
                            <img alt="card img" src={justeat} />
                        </div>
                        <h2 className="font-semibold my-4 text-2xl text-center">Just Eat</h2>
                    </div>

                    <div className="bg-white overflow-hidden rounded-lg shadow-2xl p-9 group flex-row justify-center items-center w-fit">

                        <div style={{ "height": "250px", "width": "250px", display: 'flex', justifyContent: 'center' }}>
                            <img alt="card img" src={joes} />
                        </div>
                        <h2 className="font-semibold my-4 text-2xl text-center">Joe's Ice Cream</h2>
                    </div>

                    <div className="bg-white overflow-hidden rounded-lg shadow-2xl p-9 group flex-row justify-center items-center w-fit">

                        <div style={{ "height": "250px", "width": "250px", display: 'flex', justifyContent: 'center' }}>
                            <img alt="card img" src={anatolian} />
                        </div>
                        <h2 className="font-semibold my-4 text-2xl text-center">Anatolian Cash</h2>
                    </div>




                </div>
            </div>
        </section>
    )
}

export default Clients;